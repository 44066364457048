import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SafeRedirectService {
  /**
   * Validates and returns a safe redirect URL
   * @param redirectUrl The URL to validate
   * @param defaultUrl The fallback URL if the redirect is invalid
   * @returns A safe internal URL
   */
  getSafeRedirectUrl(redirectUrl: string | null, defaultUrl: string = '/'): string {
    if (!redirectUrl) {
      return defaultUrl;
    }

    // First decode the URL if it's encoded
    try {
      redirectUrl = decodeURIComponent(redirectUrl);
    } catch (e) {
      // If decoding fails, use as is or return default
      return defaultUrl;
    }

    // Check for absolute URLs with protocols - these are the most dangerous
    if (/^https?:\/\//i.test(redirectUrl)) {
      try {
        const url = new URL(redirectUrl);
        console.log('URL hostname:', url.hostname);
        console.log('Current hostname:', window.location.hostname);
        console.log('Do hostnames match?', url.hostname === window.location.hostname);

        // Get the current domain
        const currentDomain = window.location.hostname;

        // If the domains don't match, reject the redirect
        if (url.hostname !== currentDomain) {
          console.warn(`Blocked redirect to external domain: ${url.hostname}`);
          return defaultUrl;
        }

        // If we reach here, the domain matches, so return the path portion only
        return `${url.pathname}${url.search}${url.hash}`;
      } catch (e) {
        return defaultUrl;
      }
    }

    // For relative URLs, ensure they start with a slash and don't contain protocol markers
    if (redirectUrl.startsWith('/')) {
      // Check for protocol-relative URLs (//domain.com)
      if (redirectUrl.startsWith('//') || redirectUrl.includes(':')) {
        return defaultUrl;
      }
      return redirectUrl;
    }

    // If we reach here, it's not a valid internal URL
    return defaultUrl;
  }
}
